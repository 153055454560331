
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-10 relative w-4/5 mx-auto h-full flex flex-col justify-center items-center pt-[100px] md:pt-[200px] pb-[0px] md:pb-[100px]">
          <div className="w-full flex flex-col justify-center items-center">
            <h1 className="text-white text-center text-[35px] md:text-[60px]">
              {title}
            </h1>
            <p className="text-white text-center">
              {texts}
            </p>
          </div>

          <div className="w-full flex justify-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>

        </div>
      </div>
    </>
  );
}

export default HeroSection;
